/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Preview from 'src/components/VideosList/Preview'
import { injectIntl } from 'react-intl'
import MissingContent from 'src/components/MissingContent'
import styles from './VideosListForSingleVideo.module.scss'

class VideosListForSingleVideo extends Component {
  render() {
    const { style, locale, intl, videos } = this.props

    const content = (
      <>
        <section className={styles.root} style={style}>
          <div className={styles.inner}>
            <div className={styles.wrap}>
              {videos &&
                videos.map((item) => {
                  return <Preview small key={item.videoId} {...item} locale={locale} />
                })}
            </div>
          </div>
        </section>
      </>
    )
    return videos ? content : <MissingContent locale={locale} title={intl.formatMessage({ id: 'home.missingVideo' })} />
  }
}

VideosListForSingleVideo.propTypes = {
  videos: PropTypes.array.isRequired,
  locale: PropTypes.string,
}

export default injectIntl(VideosListForSingleVideo)
