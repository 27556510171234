import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PlayButton from 'src/components/svg/PlayButton'
import YouTube from 'react-youtube'
import classNames from 'classnames'
import SocialShare from 'src/components/SocialShare'
import { getBaseUrl } from 'src/utils'
import styles from './Preview.module.scss'

class Preview extends Component {
  state = {
    isVideoPlay: false,
  }

  onVideoReady(event) {
    event.target.pauseVideo()
  }

  handleClick = () => {
    this.setState({ isVideoPlay: true })
  }

  render() {
    const opts = {
      playerVars: {
        autoplay: 1,
      },
    }

    const { title, videoId, duration, slug, small, locale } = this.props
    return (
      <article
        className={classNames(styles.root, {
          [styles.small]: small,
        })}
      >
        <figure className={styles.container}>
          <div className={styles.video}>
            {!this.state.isVideoPlay && (
              <>
                <div className={styles.preview} onClick={this.handleClick}>
                  <img
                    className={styles.image}
                    src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
                    alt={title}
                  />

                  <button className={styles.playButton}>
                    <PlayButton height="70px" width="70px" aria-label="play" />
                  </button>
                  <p className={styles.duration}>{duration}</p>
                </div>
                <SocialShare
                  title={title}
                  className={styles.share}
                  url={`${getBaseUrl()}${locale}/video/${slug}`}
                  isWhite
                  locale={locale}
                />
              </>
            )}

            {this.state.isVideoPlay && <YouTube videoId={videoId} opts={opts} />}
          </div>
        </figure>
        <div className={styles.clarification}>
          <p className={styles.title}>{title}</p>
        </div>
      </article>
    )
  }
}

Preview.propTypes = {
  title: PropTypes.string,
  videoId: PropTypes.string.isRequired,
  duration: PropTypes.string,
  small: PropTypes.bool,
  locale: PropTypes.string,
}

export default Preview
