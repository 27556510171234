import React, { Component } from 'react'
import styles from './SingleVideo.module.scss'

class SingleVideo extends Component {
  render() {
    const {
      data: { videoId, title },
    } = this.props

    return (
      <div className={styles.root}>
        <div className={styles.container}>
          <iframe
            className={styles.video}
            src={`https://www.youtube.com/embed/${videoId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={title}
          />
        </div>
      </div>
    )
  }
}

export default SingleVideo
