import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from 'src/components/Layout'
import { getBaseUrl } from 'src/utils'
import PageHelmet from 'src/components/PageHelmet'
import Footer from 'src/components/Footer'
import Header from 'src/components/Header'
import SingleVideo from 'src/components/SingleVideo'
import withLayout from 'src/components/withLayout'
import SubscribeAdditional from 'src/components/Subscribe/SubscribeAdditional'
import VideosListForSingleVideo from 'src/components/VideosListForSingleVideo'
import { FormattedMessage } from 'react-intl'

const videoToShow = 6

class Video extends Component {
  state = {
    showAllVideo: false,
  }

  toggleShowAllVideo = () => {
    this.setState({ showAllVideo: !this.state.showAllVideo })
  }

  render() {
    const {
      data: {
        video: { edges },
        subscribe,
        // channels,
      },
      pageContext: { originalPath, id },
      locale,
      changeLocale,
    } = this.props

    const { showAllVideo } = this.state
    const videos = [...edges].map(({ node }) => {
      return { ...node }
    })
    const singleVideo = { ...videos.filter((item) => item.videoId === id)[0] }
    const filteredVideo = videos.filter((item) => item.videoId !== singleVideo.videoId && item.title)

    return (
      <Layout locale={locale}>
        <PageHelmet title={singleVideo.title} url={`${getBaseUrl()}${locale}${originalPath}`} defer={false} />
        <main>
          <Header
            {...this.props}
            locale={locale}
            full={true}
            style={{ backgroundColor: '#161616' }}
            changeLocale={changeLocale}
          />
          <SingleVideo data={singleVideo} />
          <VideosListForSingleVideo videos={filteredVideo.slice(0, videoToShow)} locale={locale} />
          {showAllVideo && <VideosListForSingleVideo videos={filteredVideo.slice(videoToShow)} locale={locale} />}
          {!showAllVideo &&
            filteredVideo.length > videoToShow && (
              <button onClick={this.toggleShowAllVideo} className="btnMore">
                <FormattedMessage id="home.more" />
              </button>
            )}
          <SubscribeAdditional locale={locale} subscribe={subscribe} />
        </main>
        <Footer locale={locale} />
      </Layout>
    )
  }
}

export const query = graphql`
  query($locale: String!) {
    video: allDatoCmsVideo2(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          channel {
            title
          }
          title
          videoId
          description
          duration
          publishDate
          slug
        }
      }
    }

    channels: allDatoCmsVideoChannel(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          title
        }
      }
    }
    subscribe: file(relativePath: { eq: "subscribe-illustration.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const customProps = {
  localeKey: 'video',
}

export default withLayout(customProps)(Video)
